import React, { useEffect, useState, useMemo } from "react";
import { styled } from "@mui/material/styles";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "app/rootReducer";
import {
  ColumnMenu,
  CheckboxFilter,
  CommaDelimitedCheckboxFilter,
} from "./columnMenu";
import { ExcelExport } from "@progress/kendo-react-excel-export";
import {
  Grid as KendoGrid,
  GridColumn,
  GridColumnMenuFilter,
  GridFilterChangeEvent,
  GridPageChangeEvent,
  GridSortChangeEvent,
  GridToolbar,
  GridColumnMenuWrapper,
} from "@progress/kendo-react-grid";
import {
  orderBy,
  SortDescriptor,
  filterBy,
  CompositeFilterDescriptor,
  FilterDescriptor,
} from "@progress/kendo-data-query";
import { format, formatDate } from "@progress/kendo-intl";
import {
  Box,
  Grid,
  Paper,
  Tooltip,
  Typography,
  TextField,
  InputAdornment,
  Dialog,
  DialogActions,
  DialogContent,
  MenuItem,
  MenuList,
  Menu,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { Warning } from "@mui/icons-material";
import {
  ClearAll,
  CloudUpload,
  Search,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import AssignmentReturnedIcon from "@mui/icons-material/AssignmentReturned";
import { useDispatch } from "react-redux";
import {
  setTake,
  setSkip,
  setSort,
  setFilter,
  setDisplayColumns,
  clearFilterState,
} from "utils/filterSlice";
import {
  CommaDelimitedCell,
  CustomColorCell,
  IconCell,
  CustomLinkCell,
  CustomEmailCell,
  ExternalLinkCell,
  CustomCheckboxCell,
  CustomRatingCell,
  StatusChipCell,
  ExpandCell,
  CommaDelimitedChips,
  RichTextCell,
  TruncatedTextCell,
} from "./customCells";
import { formatStringForCompare } from "utils/functions";
import { MonthYearDateFilter } from "./filterCells/MonthYearDateFilter";
import {
  createCommaDelimitedFilter,
  createMonthYearFilter,
  createRecordTypeChipFilter,
} from "./filterCells/filterFunctions";
import { useAuth0 } from "@auth0/auth0-react";
import { generateTemplate } from "utils/uploadUtils";
import { IppButton } from "components/Buttons/IppButton";
import { IppExportButton } from "components/Buttons/IppExportButton";
import { IppSaveButton } from "components/Buttons/IppSaveButton";
import { IppCancelButton } from "components/Buttons/IppCancelButton";
import { useTypedTranslation } from "utils/customHooks";
import { EditCell } from "./inventoryCells/EditCell";
import { DeleteCell } from "./inventoryCells/DeleteCell";
import { IppAddButton } from "components/Buttons/IppAddButton";

enum DateFormats {
  MONTH_YEAR = "{0:MMM yyyy}",
  DATE = "{0:yyyy-MM-dd}",
}

const PREFIX = "IppInventory";

const classes = {
  boxSpace: `${PREFIX}-boxSpace`,
  kgrid: `${PREFIX}-kgrid`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.boxSpace}`]: {
    padding: theme.spacing(1),
  },
  [`& .${classes.kgrid}`]: {
    tableLayout: "fixed",
  },
}));

export type filterNames = "boolean" | "numeric" | "date" | "text";

export interface column {
  field: string;
  title: string;
  format?: any;
  filter?: string;
  operator?: string;
  colorFunc?: any;
  columnName?: string;
  sendEmail?: boolean;
  columnWidth?: number;
  externalLink?: boolean;
  commaDelimited?: boolean;
  commaDelimitedChips?: boolean;
  richText?: boolean;
  icons?: boolean;
  chips?: boolean;
  className?: any;
  monthyear?: boolean;
  button?: boolean;
  buttonCell?: any;
  noLink?: boolean; // disable link columns
  truncateText?: boolean;
}

type OptionsButtonsPropsBase = {
  showEdit?: boolean;
  showDelete?: boolean;
};

type EditOptions = {
  setEditOpen: (open: boolean) => void;
  setEditID: (id: number) => void;
};

type DeleteOptions = {
  setDeleteOpen: (open: boolean) => void;
  setDeleteID: (id: number) => void;
};

type OptionsButtonsProps =
  | (OptionsButtonsPropsBase &
      EditOptions & { showEdit: true; showDelete?: false })
  | (OptionsButtonsPropsBase &
      DeleteOptions & { showDelete: true; showEdit?: false })
  | (OptionsButtonsPropsBase &
      EditOptions &
      DeleteOptions & { showEdit: true; showDelete: true })
  | OptionsButtonsPropsBase;

type ButtonProps = {
  showAdd?: boolean;
  showTemplate?: boolean;
  showUpload?: boolean;
  addBtnOnClick?: () => void;
  templateType?: string;
  templatePath?: string;
};

interface IppInvProps {
  title: string;
  tableData: any;
  idField: string;
  columns: column[];
  pageable?: boolean;
  isFiltered: boolean;
  showDetail: boolean;
  sortColumn?: number; // column to sort on (defaults to zero)
  sortReverse?: boolean; // optional to reverse sort direction
  searchByColumn?: string;
  searchByPlaceholder?: string | null;
  hideExport?: boolean; // to show or hide the export to excel button
  linkColumn?: number; // specify an alternate column to use for linking
  route?: string; //used if the link route is not based off the current location path
  bulkTitle?: string;
  bulkAction?: any;
  bulkContent?: any;
  instanceId?: string;
  isExpandable?: boolean;
  detailComponent?: any;
  optionsButtons?: OptionsButtonsProps;
  buttonProps?: ButtonProps;
}

export const IppInventory = (props: IppInvProps) => {
  const { location } = useSelector((state: RootState) => state.router);
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const t = useTypedTranslation(["strGen"]);

  const {
    title,
    idField,
    columns,
    tableData,
    showDetail,
    sortColumn,
    sortReverse,
    searchByColumn,
    searchByPlaceholder,
    linkColumn,
    hideExport,
    route,
    bulkContent,
    bulkAction,
    bulkTitle,
    instanceId,
    isExpandable = false,
    detailComponent,
    optionsButtons,
    buttonProps = {},
  } = props;

  const {
    showAdd,
    showTemplate,
    showUpload,
    addBtnOnClick,
    templateType,
  }: ButtonProps = buttonProps;

  //..................... Things related to filtering ............................
  const [pageName, setPageName] = useState(window.location.pathname);
  const filterState = useSelector(
    (state: RootState) => state.filterState.filtersByInv[pageName]
  );

  useEffect(() => {
    // Update the pageName when instanceId exists
    const updatedPageName = instanceId
      ? `${window.location.pathname}_${instanceId}`
      : window.location.pathname;

    setPageName(updatedPageName);
  }, [instanceId]);

  const [filename, setFilename] = useState(title);
  const [searchTerm, setSearchTerm] = useState<any>("");
  const [stopBar, setStopBar] = useState<number>(0);
  const [bulkOpen, setBulkOpen] = useState(false);
  const [displayColumnsMenuAnchor, setDisplayColumnsMenuAnchor] = useState<
    HTMLButtonElement | undefined
  >();

  const { ft_ben_Contract } = useSelector((state: RootState) => state.client);

  const take = filterState?.take ?? 10;
  const skip = filterState?.skip ?? 0;
  const sort = useMemo(
    () => filterState?.sort ?? ([] as SortDescriptor[]),
    [filterState?.sort]
  );
  const filter = useMemo(
    () =>
      filterState?.filter ??
      ({
        filters: [] as any,
        logic: "and",
      } as CompositeFilterDescriptor),
    [filterState?.filter]
  );

  //creates and sets default filter values pending if one already exist
  if (!filterState) {
    clearFilterState(pageName);
    // determine initail sort column/direction
    dispatch(
      setSort({
        page: pageName,
        sort: [
          {
            field: columns[sortColumn ?? 0].field,
            dir: sortReverse ? "desc" : "asc",
          },
        ] as SortDescriptor[],
      })
    );
  }

  if (filter.filters?.length > 0 && stopBar < 3) {
    setStopBar(stopBar + 1);
    setSearchTerm(filter.filters[0].value);
  }

  const onSearchTermChanged = (newValue: any) => {
    const newSearchTerm = newValue.target.value;
    if (!newSearchTerm) {
      clear();
      return;
    }

    setSearchTerm(newSearchTerm);
    setIsFiltered(true);

    dispatch(
      setFilter({
        page: pageName,
        filter: {
          logic: "or",
          filters: columns.map((col) => ({
            field: col.field,
            operator: "ipp.equals",
            value: formatStringForCompare(newSearchTerm, true) as string,
          })),
        },
        take,
      })
    );
    dispatch(setSkip({ page: pageName, skip: 0 }));
    dispatch(setTake({ page: pageName, take }));
    createFileName(true);
  };

  const onSearchBarBlur = () => {
    setIsFiltered(Boolean(searchTerm));
  };

  dispatch(
    setFilter({
      page: pageName,
      filter: filter,
      take: take,
    })
  );

  const [filteredData, setFilteredData] = useState<any>([]);
  useEffect(() => {
    const _filter = Object.assign({}, filter);

    const filters = _filter.filters
      ?.filter(
        (f: CompositeFilterDescriptor | FilterDescriptor) =>
          filterState?.displayColumns?.[
            (f as FilterDescriptor).field as string
          ] ?? true
      )
      .map((f: CompositeFilterDescriptor | FilterDescriptor) => {
        const filter = { ...f } as FilterDescriptor;

        if (filter.operator === "ipp.equals") {
          filter.operator = (columnValue: any, filterValue: any) => {
            const column = columns.find(
              (c) => c.field === (f as FilterDescriptor).field
            );
            let value = columnValue;

            if (value instanceof Date) {
              const format = column?.monthyear
                ? DateFormats.MONTH_YEAR
                : column?.filter === "date"
                ? DateFormats.DATE
                : column?.format;

              value = formatDate(value, format);
            } else if (
              getFilterType(column?.filter) === "text" &&
              column?.format
            ) {
              value = format(column.format.toLowerCase(), [value]);
            }

            return (
              formatStringForCompare(value, true)?.includes(
                formatStringForCompare(filterValue, true)?.trim() ?? ""
              ) ?? false
            );
          };
        }

        return filter;
      });

    _filter.filters = filters;

    // Apply filter and sort to tableData
    setFilteredData(orderBy(filterBy(tableData, _filter), sort));
  }, [tableData, filter, sort, filterState, columns]);

  const pageChange = (event: GridPageChangeEvent) => {
    dispatch(setSkip({ page: pageName, skip: event.page.skip }));
    dispatch(setTake({ page: pageName, take: event.page.take }));
  };

  const sortChanged = (event: GridSortChangeEvent) => {
    dispatch(setSort({ page: pageName, sort: event.sort }));
  };

  const [isFiltered, setIsFiltered] = useState(false);

  // Update isFiltered when filter changes
  useEffect(() => {
    // Check if filter.filters is not empty
    setIsFiltered(filter.filters?.length > 0);
  }, [filter]);

  //there is a better-way to do all this filtering with kendo
  //but it will need changing how just about all the forms
  //built from this works
  const filterChanged = (event: GridFilterChangeEvent, columns: any) => {
    if (!event.filter) {
      dispatch(clearFilterState(pageName));
      return;
    }

    const filters = event.filter.filters.map((filter: any) => {
      // Find the column that matches the filter field
      const column = columns.find(
        (c: any) => c.field === filter.filters[0]?.field
      );

      // Check if the column requires special month-year formatting
      if (column?.monthyear) {
        // apply custom filter function
        return createMonthYearFilter(filter);
      } else if (column?.isRecordTypeChips) {
        return createRecordTypeChipFilter(filter);
      } else if (column?.commaDelimited) {
        return createCommaDelimitedFilter(filter);
      } else if (
        typeof filter.value === "string" &&
        // if filter field is searchBox Colum, then no change
        filter.field !== searchByColumn
      ) {
        return {
          field: filter.field,
          // set operator in your column props if you want other operator like: contains, startswith...
          // available operators: https://www.telerik.com/kendo-react-ui/components/dataquery/api/FilterDescriptor/
          operator: column?.operator || "eq",
          value: formatStringForCompare(filter.value, true) as string,
        };
      }
      return filter;
    });

    dispatch(
      setFilter({
        page: pageName,
        filter: {
          ...event.filter,
          filters,
        },
      })
    );
    dispatch(setSkip({ page: pageName, skip: 0 }));
    dispatch(setTake({ page: pageName, take: take }));
    createFileName(true);
  };

  const getFilterType = (filter: any) =>
    ["date", "text", "boolean", "numeric"].includes(filter ?? "text")
      ? filter
      : "text";

  const commaDelimitedFilter = (props: any) => (
    <CommaDelimitedCheckboxFilter {...props} data={tableData} />
  );

  const checkboxFilter = (props: any) => (
    <CheckboxFilter {...props} data={tableData} />
  );

  const clear = () => {
    dispatch(clearFilterState(pageName));
    setSearchTerm("");
    setIsFiltered(false); // Reset isFiltered state to false
    createFileName(false);
  };

  const createFileName = (isFiltered: boolean) => {
    const strDate = new Date().toISOString().split("T")[0];
    if (isFiltered) {
      setFilename(title + " - " + strDate + " - filtered");
    } else {
      setFilename(title + " - " + strDate);
    }
  };

  //................. end of filter things ................................

  //IppDebug("Inventory", filter);

  //Set Upload template URL
  const pathName: string = `${location.pathname}`;
  const baseFilePath = `${process.env.REACT_APP_FILE_STORAGE}/templates/`;
  let templateName: string = "";

  switch (pathName) {
    case "/benefits/payments":
      templateName = ft_ben_Contract
        ? `PaymentsWithContractUpload`
        : `PaymentsUpload`;
      break;

    case "/benefits/employees":
      templateName = `EmployeesUpload`;
      break;

    case "/engagement/communications":
      templateName = `CommunicationTemplate`;
      break;

    case "/engagement/contacts":
    case "/commitments/contacts":
      templateName = `ContactTemplate`;
      break;

    case "/engagement/groups":
    case "/commitments/groups":
      templateName = `GroupTemplate`;
      break;

    default:
      break;
  }

  const { currentProfile, currentUserRoleList, currentUserRolesById } =
    useSelector((state: RootState) => state.profile);

  // set canWRITE to true for Client Admins
  let canWrite = true;

  if (!currentProfile.IsClientAdmin) {
    interface PathRoleMap {
      [key: string]: number[];
    }

    const userRoles = currentUserRoleList.map((id) => currentUserRolesById[id]);

    // Create an Object to Map the Roles with WRITE access to the Module paths
    const pathRoleMap: PathRoleMap = {
      "/benefits": [1, 2],
      "/commitments": [3, 4],
      "/engagement": [5],
      "/contacts": [3, 4, 5],
      "/groups": [3, 4, 5],
      "/issues": [5],
    };

    // For each key, check if the pathName includes the key
    Object.keys(pathRoleMap).forEach((path) => {
      if (pathName.includes(path)) {
        // set canWRITE to true if the current User has a ModuleRoleID that is included in the path Object's Array, if not, canWRITE will be false
        canWrite = userRoles.some((role) =>
          pathRoleMap[path].includes(role.ModuleRoleID)
        );
      }
    });
  }

  const templatePath: string = baseFilePath + templateName + `.xlsx`;

  // exporting to excel
  const _exporter = React.createRef<ExcelExport>();

  const HeaderCell = (props: any) => (
    <span className="k-cell-inner" title={props.title}>
      <span className="k-link" onClick={props.onClick}>
        <span className="k-column-title">
          {props.title}
          {props.children}
        </span>
      </span>
      <GridColumnMenuWrapper
        {...props.columnMenuWrapperProps}
      ></GridColumnMenuWrapper>
    </span>
  );

  // Expand actions
  const onItemChange = (event: any) => {
    setFilteredData((prevData: any) =>
      prevData.map((item: any) =>
        item[idField] === event.dataItem[idField]
          ? { ...item, expanded: !item.expanded }
          : item
      )
    );
  };

  const ippInvView = (
    <Box display="flex">
      <Paper className={classes.boxSpace}>
        <Grid container spacing={1}>
          <Grid item xs={8}>
            <Typography variant="h5" component="h1">
              {title}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            {showAdd && canWrite && (
              <Grid container justifyContent="flex-end">
                {bulkTitle && bulkAction && (
                  <Tooltip title={t("strGen:buttons.tooltip.bulkaction")}>
                    <IppButton
                      variant="outlined"
                      color="secondary"
                      onClick={() => setBulkOpen(!bulkOpen)}
                    >
                      {bulkTitle}
                    </IppButton>
                  </Tooltip>
                )}
                <Tooltip title={t("strGen:buttons.tooltip.addnewentry")}>
                  {addBtnOnClick ? (
                    <IppAddButton
                      buttonText={t("strGen:buttons.addnew")}
                      onClick={addBtnOnClick}
                    />
                  ) : (
                    <IppButton
                      component={Link}
                      to={`${location.pathname}/new`}
                      startIcon={<AddIcon />}
                      color={"secondary"}
                    >
                      {t("strGen:buttons.addnew")}
                    </IppButton>
                  )}
                </Tooltip>
                {showTemplate && canWrite && (
                  <Tooltip
                    title={t("strGen:buttons.tooltip.downloadexceltemplate")}
                  >
                    {templateType ? (
                      <IppButton
                        startIcon={<AssignmentReturnedIcon />}
                        onClick={() =>
                          generateTemplate(getAccessTokenSilently, templateType)
                        }
                        color={"secondary"}
                        variant="outlined"
                      >
                        {t("strGen:buttons.template")}
                      </IppButton>
                    ) : (
                      <a
                        href={templatePath}
                        // following line removed to avoid blank tab in Edge
                        // target="_blank"
                        rel="noopener noreferrer"
                        style={{ textDecorationLine: "none", color: "inherit" }}
                        download
                      >
                        <IppButton
                          startIcon={<AssignmentReturnedIcon />}
                          color={"secondary"}
                          variant="outlined"
                        >
                          {t("strGen:buttons.template")}
                        </IppButton>
                      </a>
                    )}
                  </Tooltip>
                )}
                {showUpload && (
                  <Tooltip title={t("strGen:buttons.tooltip.upload")}>
                    <IppButton
                      component={Link}
                      to={`${location.pathname}/upload`}
                      startIcon={<CloudUpload />}
                      variant="outlined"
                      color={"secondary"}
                    >
                      {t("strGen:buttons.upload")}
                    </IppButton>
                  </Tooltip>
                )}
              </Grid>
            )}
          </Grid>
          <Grid item xs={12}>
            <ExcelExport
              data={filteredData}
              filterable={false}
              ref={_exporter}
              fileName={filename}
            >
              <KendoGrid
                data={
                  idField === "ThreadID"
                    ? filteredData.slice().reverse()
                    : filteredData
                }
                pageable={{
                  buttonCount: 5,
                  info: true,
                  // type: "input",
                  pageSizes: [5, 10, 25, 50, 100],
                }}
                skip={skip}
                take={take}
                total={filteredData.length}
                onPageChange={pageChange}
                sortable={true}
                groupable={false}
                sort={sort}
                onSortChange={(e) => sortChanged(e)}
                filter={filter}
                onFilterChange={(e) => filterChanged(e, columns)}
                rowHeight={1}
                expandField={isExpandable ? "expanded" : ""}
                onItemChange={onItemChange}
                detail={detailComponent}
              >
                <GridToolbar>
                  <Grid container spacing={1} alignItems="center">
                    <Grid
                      item
                      xs={12}
                      md={searchByColumn ? 6 : 12}
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      {!hideExport && (
                        <Tooltip
                          title={t("strGen:buttons.tooltip.exportalltoexcel")}
                        >
                          <IppExportButton exporter={_exporter} />
                        </Tooltip>
                      )}

                      <Tooltip
                        title={t("strGen:buttons.tooltip.displayColumns")}
                      >
                        <IppButton
                          variant="outlined"
                          onClick={(
                            event: React.MouseEvent<HTMLButtonElement>
                          ) => setDisplayColumnsMenuAnchor(event.currentTarget)}
                          startIcon={<Visibility />}
                          color={"secondary"}
                        >
                          {t("strGen:buttons.displayColumns")}
                        </IppButton>
                      </Tooltip>

                      <Menu
                        open={Boolean(displayColumnsMenuAnchor)}
                        anchorEl={displayColumnsMenuAnchor}
                        onClose={() => setDisplayColumnsMenuAnchor(undefined)}
                      >
                        <MenuList dense>
                          {columns
                            .slice(2) // Exclude the first two columns
                            .map((col) => (
                              <MenuItem
                                key={col.field}
                                onClick={() => {
                                  dispatch(
                                    setDisplayColumns({
                                      page: pageName,
                                      columns: {
                                        [col.field]: !(
                                          filterState?.displayColumns?.[
                                            col.field
                                          ] ?? true
                                        ),
                                      },
                                    })
                                  );
                                }}
                              >
                                <ListItemIcon>
                                  {filterState?.displayColumns?.[col.field] ??
                                  true ? (
                                    <Visibility fontSize="small" />
                                  ) : (
                                    <VisibilityOff fontSize="small" />
                                  )}
                                </ListItemIcon>
                                <ListItemText>{col.title}</ListItemText>
                              </MenuItem>
                            ))}
                        </MenuList>
                      </Menu>

                      <Tooltip
                        title={t("strGen:buttons.tooltip.clearallfilters")}
                      >
                        <IppButton
                          variant="outlined"
                          onClick={clear}
                          startIcon={<ClearAll />}
                          color={"secondary"}
                          style={{ marginRight: "10px" }} // Add margin to create space between the button and the message
                        >
                          {t("strGen:buttons.clearfilters")}
                        </IppButton>
                      </Tooltip>

                      {isFiltered && (
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <p style={{ fontSize: "14px", margin: 0 }}>
                            {t(
                              "strGen:components.ippinventory.resultsfiltered"
                            )}
                          </p>
                          <Warning color="warning" />
                        </div>
                      )}
                    </Grid>
                    <Grid item xs={12} md={6} lg={6} justifyContent="flex-end">
                      {searchByColumn ? (
                        <TextField
                          id="Search"
                          label={t(
                            "strGen:components.ippinventory.searchbarlabel"
                          )}
                          size="small"
                          value={searchTerm}
                          placeholder={
                            t("strGen:filters.TypeForAnything") || ""
                          }
                          onChange={onSearchTermChanged}
                          onBlur={onSearchBarBlur} // Add this onBlur event handler
                          InputLabelProps={{ shrink: true }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="start">
                                <Search fontSize={"small"} />
                              </InputAdornment>
                            ),
                          }}
                        />
                      ) : (
                        ""
                      )}
                    </Grid>
                  </Grid>
                </GridToolbar>
                {/* Expand column */}
                {isExpandable && (
                  <GridColumn
                    field="expanded"
                    title=" "
                    width={40}
                    cell={ExpandCell}
                    editable={false}
                  />
                )}
                {/* "filter prop works in reverse for link column. Text cause checkbox filter " */}
                {columns
                  .filter(
                    (col) => filterState?.displayColumns?.[col.field] ?? true
                  )
                  .map((col: column, ci: number) => {
                    if (
                      showDetail &&
                      (ci === 0 || ci === linkColumn) &&
                      !col?.noLink
                    ) {
                      return (
                        <GridColumn
                          key={col.title}
                          field={col.field}
                          title={col.title}
                          format={col.format}
                          width={col.columnWidth ? col.columnWidth : undefined}
                          cell={(props) => (
                            <CustomLinkCell
                              {...props}
                              format={col.format}
                              path={
                                route
                                  ? `${route}/${props.dataItem[idField]}`
                                  : `${location.pathname}/${props.dataItem[idField]}`
                              }
                            />
                          )}
                          columnMenu={
                            col.filter === undefined
                              ? ColumnMenu
                              : checkboxFilter
                          }
                          headerClassName={
                            GridColumnMenuFilter.active(
                              col.field,
                              filter as CompositeFilterDescriptor
                            )
                              ? "active"
                              : ""
                          }
                          headerCell={HeaderCell}
                        />
                      );
                    } else if (col.chips) {
                      return (
                        <GridColumn
                          key={col.title}
                          field={col.field}
                          title={col.title}
                          width={col.columnWidth ? col.columnWidth : undefined}
                          cell={(props) => <StatusChipCell {...props} />}
                          columnMenu={checkboxFilter}
                          headerClassName={
                            GridColumnMenuFilter.active(
                              col.field,
                              filter as CompositeFilterDescriptor
                            )
                              ? "active"
                              : ""
                          }
                          headerCell={HeaderCell}
                        />
                      );
                    } else if (col.icons) {
                      return (
                        <GridColumn
                          key={col.title}
                          field={col.field}
                          title={col.title}
                          width={col.columnWidth ? col.columnWidth : undefined}
                          cell={(props) => <IconCell {...props} />}
                          columnMenu={checkboxFilter}
                          headerClassName={
                            GridColumnMenuFilter.active(
                              col.field,
                              filter as CompositeFilterDescriptor
                            )
                              ? "active"
                              : ""
                          }
                          headerCell={HeaderCell}
                        />
                      );
                    } else if (col.commaDelimited) {
                      return (
                        <GridColumn
                          key={col.title}
                          field={col.field}
                          title={col.title}
                          cell={CommaDelimitedCell}
                          columnMenu={commaDelimitedFilter}
                          headerClassName={
                            GridColumnMenuFilter.active(
                              col.field,
                              filter as CompositeFilterDescriptor
                            )
                              ? "active"
                              : ""
                          }
                          headerCell={HeaderCell}
                        />
                      );
                    } else if (col.commaDelimitedChips) {
                      return (
                        <GridColumn
                          key={col.title}
                          field={col.field}
                          title={col.title}
                          cell={CommaDelimitedChips}
                          columnMenu={commaDelimitedFilter}
                          headerClassName={
                            GridColumnMenuFilter.active(
                              col.field,
                              filter as CompositeFilterDescriptor
                            )
                              ? "active"
                              : ""
                          }
                          headerCell={HeaderCell}
                        />
                      );
                    } else if (col.richText) {
                      return (
                        <GridColumn
                          key={col.title}
                          field={col.field}
                          title={col.title}
                          cell={RichTextCell}
                          columnMenu={ColumnMenu}
                          headerClassName={
                            GridColumnMenuFilter.active(
                              col.field,
                              filter as CompositeFilterDescriptor
                            )
                              ? "active"
                              : ""
                          }
                          headerCell={HeaderCell}
                        />
                      );
                    } else if (col.truncateText) {
                      return (
                        <GridColumn
                          key={col.title}
                          field={col.field}
                          title={col.title}
                          cell={TruncatedTextCell}
                          columnMenu={ColumnMenu}
                          headerClassName={
                            GridColumnMenuFilter.active(
                              col.field,
                              filter as CompositeFilterDescriptor
                            )
                              ? "active"
                              : ""
                          }
                          headerCell={HeaderCell}
                        />
                      );
                    } else if (col.externalLink) {
                      return (
                        <GridColumn
                          key={col.title}
                          field={col.field}
                          title={col.title}
                          cell={ExternalLinkCell}
                          columnMenu={ColumnMenu}
                          headerClassName={
                            GridColumnMenuFilter.active(
                              col.field,
                              filter as CompositeFilterDescriptor
                            )
                              ? "active"
                              : ""
                          }
                          headerCell={HeaderCell}
                        />
                      );
                    } else if (col.sendEmail) {
                      return (
                        <GridColumn
                          key={col.title}
                          field={col.field}
                          title={col.title}
                          cell={CustomEmailCell}
                          columnMenu={ColumnMenu}
                          headerClassName={
                            GridColumnMenuFilter.active(
                              col.field,
                              filter as CompositeFilterDescriptor
                            )
                              ? "active"
                              : ""
                          }
                          headerCell={HeaderCell}
                        />
                      );
                    } else if (col.monthyear) {
                      return (
                        <GridColumn
                          key={col.title}
                          field={col.field}
                          title={col.title}
                          format={DateFormats.MONTH_YEAR}
                          filter="date"
                          columnMenu={(props) => (
                            <GridColumnMenuFilter
                              {...props}
                              filterUI={MonthYearDateFilter}
                              expanded={true}
                            />
                          )}
                          className={col.className ? col.className : undefined}
                          headerClassName={
                            GridColumnMenuFilter.active(
                              col.field,
                              filter as CompositeFilterDescriptor
                            )
                              ? "active"
                              : ""
                          }
                          headerCell={HeaderCell}
                          width={col.columnWidth ? col.columnWidth : undefined}
                        />
                      );
                    } else if (col?.button) {
                      return (
                        <GridColumn
                          cell={col?.buttonCell}
                          width={col.columnWidth ? col.columnWidth : undefined}
                        />
                      );
                    } else {
                      // get type of field and call appropriate cell type
                      switch (col.filter) {
                        case "boolean":
                          return (
                            <GridColumn
                              key={col.title}
                              field={col.field}
                              title={col.title}
                              format={col.format}
                              filter={getFilterType(col.filter)}
                              className={
                                col.className ? col.className : undefined
                              }
                              columnMenu={
                                col.filter === undefined
                                  ? checkboxFilter
                                  : ColumnMenu
                              }
                              cell={CustomCheckboxCell}
                              headerClassName={
                                GridColumnMenuFilter.active(
                                  col.field,
                                  filter as CompositeFilterDescriptor
                                )
                                  ? "active"
                                  : ""
                              }
                              headerCell={HeaderCell}
                              width={
                                col.columnWidth ? col.columnWidth : undefined
                              }
                            />
                          );

                        case "rating":
                          return (
                            <GridColumn
                              key={col.title}
                              field={col.field}
                              title={col.title}
                              format={col.format}
                              filter={"numeric"}
                              columnMenu={ColumnMenu}
                              cell={CustomRatingCell}
                              headerClassName={
                                GridColumnMenuFilter.active(
                                  col.field,
                                  filter as CompositeFilterDescriptor
                                )
                                  ? "active"
                                  : ""
                              }
                              headerCell={HeaderCell}
                            />
                          );

                        default:
                          return (
                            <GridColumn
                              key={col.title}
                              field={col.field}
                              title={col.title}
                              format={
                                col.filter === "date"
                                  ? DateFormats.DATE
                                  : col.format
                              }
                              filter={getFilterType(col.filter)}
                              className={
                                col.className ? col.className : undefined
                              }
                              cell={
                                col.colorFunc
                                  ? (props) => (
                                      <CustomColorCell
                                        {...props}
                                        colorFunction={col.colorFunc}
                                      />
                                    )
                                  : undefined
                              }
                              columnMenu={
                                col.filter === undefined
                                  ? checkboxFilter
                                  : ColumnMenu
                              }
                              headerClassName={
                                GridColumnMenuFilter.active(
                                  col.field,
                                  filter as CompositeFilterDescriptor
                                )
                                  ? "active"
                                  : ""
                              }
                              headerCell={HeaderCell}
                              width={
                                col.columnWidth ? col.columnWidth : undefined
                              }
                            />
                          );
                      }
                    }
                  })}

                {optionsButtons?.showEdit &&
                  "setEditOpen" in optionsButtons && (
                    <GridColumn
                      cell={(props) => (
                        <EditCell
                          {...props}
                          idField={idField}
                          setID={optionsButtons.setEditID}
                          setEditOpen={optionsButtons.setEditOpen}
                        />
                      )}
                      width="50px"
                      title=" "
                    />
                  )}

                {optionsButtons?.showDelete &&
                  "setDeleteOpen" in optionsButtons && (
                    <GridColumn
                      cell={(props) => (
                        <DeleteCell
                          {...props}
                          idField={idField}
                          setID={optionsButtons.setDeleteID}
                          setDeleteOpen={optionsButtons.setDeleteOpen}
                        />
                      )}
                      width="50px"
                      title=" "
                    />
                  )}
              </KendoGrid>
            </ExcelExport>
          </Grid>

          <Dialog open={bulkOpen} fullWidth maxWidth="lg">
            <DialogContent>
              {bulkContent ? bulkContent[0] : undefined}
            </DialogContent>

            <DialogActions>
              <IppSaveButton
                buttonText={t("strGen:buttons.continue")}
                handleSave={() => {
                  setBulkOpen(false);
                  bulkAction(filteredData);
                }}
                disabled={bulkContent ? bulkContent[1] : false}
              />
              <IppCancelButton onClick={() => setBulkOpen(!bulkOpen)} />
            </DialogActions>
          </Dialog>
        </Grid>
      </Paper>
    </Box>
  );

  return <Root id="impact-inventory-page">{ippInvView}</Root>;
};

IppInventory.defaultProps = {
  showUpload: false,
  linkColumn: 0,
  instanceId: null,
};
