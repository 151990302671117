import { useFormik } from "formik";
import { styled } from "@mui/material/styles";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "app/rootReducer";
import { useAuth0 } from "@auth0/auth0-react";
import {
  addStakeholderGroup,
  updStakeholderGroup,
  delStakeholderGroup,
  fetchStakeholderGroups,
} from "./StakeholderGroupSlice";
import {
  Box,
  Dialog,
  DialogContent,
  Grid,
  Paper,
  Tooltip,
  Typography,
  Tabs,
  Tab,
} from "@mui/material";
import { ChangeEvent, useEffect, useState } from "react";
import { IppFormHeader } from "components/IppFormHeader";
import { IppFormButtons } from "components/Buttons/IppFormButtons";
import { IppTextField } from "components/IppTextField";
import { IppFormDivider } from "components/IppFormDivider";
import { IppAutocomplete } from "components/IppAutocomplete";
import { IppDisplayField } from "components/IppDisplayField";
import LoadingIndicator from "components/LoadingIndicator";
import { IppChildInventory } from "components/Inventory/IppChildInventory";
import { IppAttachmentInventory } from "components/IppAttachmentInventory";
import { Prompt } from "react-router";
import { fetchClientOrganizationTypes } from "../admin/clientOrganizationType/ClientOrganizationTypeSlice";
import { IppRating } from "components/IppRating";
import { fetchContactGroups } from "../../stakeholder/contactGroup/ContactGroupSlice";
import { fetchGroupInteractionsByGroup } from "../../stakeholder/groupInteraction/GroupInteractionSlice";
import { fetchIndigenousGroupsByClient } from "features/datalists/IndigenousGroupSlice";
import { PersonRenderOption } from "utils/renderFunctions";
import { transformPILSDs } from "utils/functions";
import { IppContactAutoComplete } from "components/IppContactAutoComplete";
import { fetchContacts } from "../contacts/ContactSlice";
import { Contact } from "../../../api/stakeholder/contactAPI";
import { fetchDeleteChecksByGroup } from "features/deleteCheck/DeleteCheckSlice";
import { IppCheckbox } from "components/IppCheckbox";
import { fetchInteractions } from "../../stakeholder/interaction/InteractionSlice";
import { fetchGrievanceGroupsByGroup } from "../../stakeholder/grievanceGroup/GrievancGroupSlice";
import {
  ConvertDateOffset,
  getDateFromDateString,
} from "../../../utils/DateFunctions";
import { fetchGrievances } from "../../stakeholder/grievance/GrievanceSlice";
import { getCurrentModule } from "../../../utils/urlUtils";
import { GroupCheckDuplicates } from "./StakeholderGroupCheckDuplicatePage";
import { IppTabPanel } from "components/IppTabPanel";
import { fetchUsers } from "features/users/UsersSlice";
import { countAttachments } from "api/attachmentsAPI";
import { fetchLevelOfImportances } from "features/datalists/LevelOfImportanceSlice";
import { User } from "api/usersAPI";
import { UserRole } from "api/userRoleAPI";
import { ClientModule } from "api/clientModuleAPI";
import { fetchGroupIssuesByGroup } from "features/stakeholder/groupIssue/GroupIssueSlice";
import { fetchIssues } from "features/stakeholder/issue/IssueSlice";
import { IppTokenAutocomplete } from "components/IppTokenAutoComplete";
import {
  fetchCommitments,
  fetchCommitmentsByGroup,
} from "features/commitments/commitment/CommitmentSlice";
import { IppMultiTextField } from "components/IppMultiTextField";
import { IsMedSmall } from "utils/mediaQueries";
import { useSnackBarConstants, useTypedTranslation } from "utils/customHooks";
import { fetchOrganizationTypes } from "features/datalists/OrganizationTypeSlice";
import { IppDisplayChip } from "components/IppDisplayChip";
import { UserWriteAccess } from "utils/userAccess";
import { ValidationSchema } from "./StakeholderGroupValidation";
import { IppLocationDisplay } from "components/IppLocationDisplay";
import { IppLocationAutoComplete } from "components/IppLocationAutoComplete";
import { IppMergeButton } from "components/Buttons/IppMergeButton";
import { fetchProximityIndicatorLSDs } from "features/datalists/PILSDSlice";
import { IppDynamicPILSD } from "components/IppDynamicPILSD";
import { selectAllUserRoles } from "features/roles/UserRoleSlice";
import { fetchCommitmentGroupsByGroup } from "features/commitments/commitmentGroup/commitmentGroupSlice";
import { RecordHistoryDisplay } from "components/Reusable Utils/RecordHistoryDisplay";
import { IppDisplayCheckbox } from "components/IppDisplayCheckbox";

const PREFIX = "StakeholderGroupForm";

const classes = {
  editForm: `${PREFIX}-editForm`,
  boxSpace: `${PREFIX}-boxSpace`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.editForm}`]: {
    // minWidth: 650,
    maxWidth: 1150,
  },

  [`& .${classes.boxSpace}`]: {
    padding: theme.spacing(1),
  },
}));

export const StakeholderGroupForm = (props: any) => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const t = useTypedTranslation(["objPlt", "strGen"]);
  const snackbarConstants = useSnackBarConstants();
  const customBP = IsMedSmall();

  const [activeTab, setActiveTab] = useState(0);
  const [didSaveInventory, setDidSaveInventory] = useState(false);
  const [showDuplicateCheck, setShowDuplicateCheck] = useState(false);
  const [listComms, setListComms] = useState<Array<string>>([]);
  let itemData = props.stakeholderGroup || {};
  const showEdit = UserWriteAccess("EngComm");

  let showCommunications = true;
  let showContacts = true;
  let showGrievances = true;
  let showIssues = true;
  let showAttachments = true;
  let showCommitments = true;
  let index: number[] = [];

  const {
    clientId,
    isLoading: clientIsLoading,
    ft_eng_lvlOfEngagement,
    ft_eng_GroupIssue,
    ft_eng_Grievance,
    ft_eng_PILSD,
    ft_eng_Importance,
  } = useSelector((state: RootState) => state.client);

  //We want to control the tabs we display to the user depending on the module.
  let currentModule: string;
  switch (getCurrentModule()) {
    case "commitments":
      currentModule = "commitments";
      showCommunications = false;
      showGrievances = false;
      showIssues = false;
      index = [-1, 0, 1, -1, -1, 2];
      break;
    case "engagement":
      currentModule = "engagement";
      showCommitments = false;
      if (ft_eng_GroupIssue) {
        index = [0, -1, 1, 2, 3, 4];
      } else {
        showIssues = false;
        index = [0, -1, 1, 2, -1, 3];
      }
      break;
    default:
  }

  useEffect(() => {
    if (currentModule === "commitments") {
      setActiveTab(1);
    }
  }, []);

  const [isEditing, setIsEditing] = useState(itemData.GroupID ? false : true);
  const [isAdding, setIsAdding] = useState(itemData.GroupID ? false : true);
  const [selectedContacts, setSelectedContacts] = useState<Contact[]>([]);
  const [listIssues, setListIssues] = useState<Array<String>>([]);

  const [pageLoading, setPageLoading] = useState(true);
  // used to display attachments count
  const [attachCount, setAttachCount] = useState({ Count: 0 } as any);

  const { currentProfile } = useSelector((state: RootState) => state.profile);

  const {
    indigenousGroupList,
    indigenousGroupsById,
    error: indigenousGroupError,
    isLoading: indigenousGroupIsLoading,
  } = useSelector((state: RootState) => state.indigenousGroups);

  const indigenousGroups = indigenousGroupList.map(
    (id) => indigenousGroupsById[id]
  );

  const {
    levelOfImportanceList,
    levelOfImportancesById,
    error: levelOfImportanceError,
    isLoading: levelOfImportanceIsLoading,
  } = useSelector((state: RootState) => state.levelOfImportances);

  const levelOfImportance = levelOfImportanceList.map(
    (id) => levelOfImportancesById[id]
  );

  const {
    organizationTypeList,
    organizationTypesById,
    isLoading: organizationTypesIsLoading,
    error: organizationTypesError,
  } = useSelector((state: RootState) => state.organizationTypes);

  const organizationTypes = organizationTypeList.map(
    (id) => organizationTypesById[id]
  );

  const {
    clientOrganizationTypeList,
    clientOrganizationTypesById,
    isLoading: clientOrganizationTypesIsLoading,
    error: clientOrganizationTypesError,
  } = useSelector((state: RootState) => state.clientOrganizationTypes);

  const clientOrganizationTypes = clientOrganizationTypeList.map(
    (id) => clientOrganizationTypesById[id]
  );

  const { clientModuleList, clientModulesById } = useSelector(
    (state: RootState) => state.clientModules
  );

  const clientmodules = clientModuleList.map((id) => clientModulesById[id]);

  const { userList, usersById } = useSelector(
    (state: RootState) => state.users
  );

  const userRoles = useSelector((state: RootState) =>
    selectAllUserRoles(state)
  );

  // Filter for active users only
  const allUsers = userList.map((id) => usersById[id]);
  let users = allUsers.filter((item: User) => {
    if (!item.IsActive || item.CompanyID != currentProfile.CompanyID)
      return false;
    if (item.IsClientAdmin) {
      return true;
    } else {
      let found = userRoles.filter((role) => {
        return item.UserAccountID === role.UserAccountID;
      });
      if (found && found.length > 0) {
        let check = clientmodules.find((object: ClientModule) => {
          let clientMod = found.find((mod: UserRole) => {
            return (
              object.ClientModuleID === mod.ClientModuleID &&
              (object.ModuleID === 3 || object.ModuleID === 2)
            );
          });
          return clientMod;
        });
        return check;
      } else return false;
    }
  });

  const {
    contactList,
    contactsById,
    isLoading: contactIsLoading,
  } = useSelector((state: RootState) => state.contacts);

  const contacts = contactList.map((id) => contactsById[id]);

  const {
    contactGroupList,
    contactGroupsById,
    isLoading: contactGroupIsLoading,
  } = useSelector((state: RootState) => state.contactGroups);

  const groups = contactGroupList.map((id) => contactGroupsById[id]);
  const contactGroups = groups
    .filter((group) => group.GroupID === itemData.GroupID)
    .map((p) => {
      let n = Object.assign({}, p) as any;
      n.ContactName = p.ContactName
        ? p.ContactName.trim() !== ""
          ? p.ContactName.trim()
          : `* ${t("objPlt:objects.contact.missingname")} *`
        : `* ${t("objPlt:objects.contact.missingname")} *`;
      return n;
    });

  const { issueList, issuesById } = useSelector(
    (state: RootState) => state.issues
  );

  const issues = issueList.map((id) => issuesById[id]);

  const {
    groupIssueList,
    groupIssuesById,
    isLoading: groupIssueIsLoading,
  } = useSelector((state: RootState) => state.groupIssues);

  const groupIssuesUnfiltered = groupIssueList.map((id) => groupIssuesById[id]);
  const groupIssues = groupIssuesUnfiltered.filter(
    (issue) => issue.GroupID === itemData.GroupID
  );

  const {
    stakeholderGroupList,
    stakeholderGroupsById,
    isLoaded: groupsLoaded,
  } = useSelector((state: RootState) => state.stakeholderGroups);

  const stakeholdergroups = stakeholderGroupList.map(
    (id) => stakeholderGroupsById[id]
  );

  const { groupInteractionList, groupInteractionsById } = useSelector(
    (state: RootState) => state.groupInteractions
  );

  const groupInteractionsUnfiltered = groupInteractionList.map(
    (id) => groupInteractionsById[id]
  );

  const groupInteractions = groupInteractionsUnfiltered.filter(
    (interactions) => interactions.GroupID === itemData.GroupID
  );

  const gi = groupInteractions.map((p) => {
    let newObj = Object.assign({}, p);
    newObj.InteractionDate = ConvertDateOffset(new Date(p.InteractionDate));

    return newObj;
  });

  const { interactionList, interactionsById } = useSelector(
    (state: RootState) => state.interactions
  );

  const interactions = interactionList.map((id) => interactionsById[id]);

  const { grievanceList, grievancesById } = useSelector(
    (state: RootState) => state.grievances
  );

  const grievances = grievanceList.map((id) => grievancesById[id]);

  const { grievanceGroupList, grievanceGroupsById } = useSelector(
    (state: RootState) => state.grievanceGroups
  );

  const grievanceGroupsUnfiltered = grievanceGroupList.map(
    (id) => grievanceGroupsById[id]
  );

  const grievanceGroups = grievanceGroupsUnfiltered.filter(
    (grievances) => grievances.GroupID === itemData.GroupID
  );

  const gg = grievanceGroups.map((p) => {
    let n = Object.assign({}, p) as any;
    if (p.ReportedDate !== null) {
      n.ReportedDate = ConvertDateOffset(p.ReportedDate);
    } else {
      n.ReportedDate = null;
    }

    if (p.ResolutionDate !== null) {
      n.ResolutionDate = ConvertDateOffset(p.ResolutionDate);
    } else {
      n.ResolutionDate = null;
    }

    return n;
  });

  const {
    commitmentList,
    commitmentsById,
    isLoading: commitmentIsLoading,
  } = useSelector((state: RootState) => state.commitments);

  const commitments = commitmentList.map((id) => commitmentsById[id]);

  const {
    commitmentGroupList,
    commitmentGroupsById,
    isLoading: commitmentGroupIsLoading,
  } = useSelector((state: RootState) => state.commitmentGroups);

  const commitmentGroups = commitmentGroupList.map(
    (id) => commitmentGroupsById[id]
  );

  // get lists for commitments  autocomplete fields
  useEffect(() => {
    // effect
    if (!commitmentIsLoading) {
      let newList: string[] = [];
      commitments.map((item) => newList.push(item.CommitmentTitle));
      setListComms(newList);
    }
    return () => {
      // cleanup
    };
  }, [itemData.GroupID, commitmentIsLoading, isEditing]);

  //usestate for Rootstate of attachment
  const { attachmentsById, attachmentList } = useSelector(
    (state: RootState) => state.attachments
  );

  const attachments = attachmentList.map((id) => attachmentsById[id]);

  const { proximityIndicatorLSDList, proximityIndicatorLSDsById } = useSelector(
    (state: RootState) => state.proximityIndicatorLSDs
  );

  const proximityIndicatorLSDs = ft_eng_PILSD
    ? proximityIndicatorLSDList.map((id) => proximityIndicatorLSDsById[id])
    : [];

  useEffect(() => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        if (!groupsLoaded) {
          dispatch(fetchStakeholderGroups(accessToken));
        }
        if (currentModule === "engagement") {
          dispatch(fetchGroupIssuesByGroup(accessToken, itemData.GroupID));
          dispatch(
            fetchGroupInteractionsByGroup(accessToken, itemData.GroupID)
          );
          dispatch(fetchIssues(accessToken));
          dispatch(fetchInteractions(accessToken));
          dispatch(fetchGrievances(accessToken));
          dispatch(fetchGrievanceGroupsByGroup(accessToken, itemData.GroupID));
        }
        if (currentModule === "commitments") {
          dispatch(fetchCommitments(accessToken));
          if (!isAdding) {
            dispatch(
              fetchCommitmentGroupsByGroup(accessToken, itemData.GroupID)
            );
          }
        }
        dispatch(fetchIndigenousGroupsByClient(accessToken));
        dispatch(fetchOrganizationTypes(accessToken));
        dispatch(fetchClientOrganizationTypes(accessToken));
        dispatch(fetchContactGroups(accessToken));
        dispatch(fetchContacts(accessToken));
        dispatch(fetchUsers(accessToken, clientId));
        if (ft_eng_Importance && !ft_eng_lvlOfEngagement) {
          dispatch(fetchLevelOfImportances(accessToken));
        }
        if (ft_eng_PILSD) {
          dispatch(fetchProximityIndicatorLSDs(accessToken));
        }
        if (!isAdding) {
          const count = await countAttachments(
            accessToken,
            "StakeholderGroups",
            itemData.GroupID
          );
          setAttachCount(count.attachments ? count.attachments : 0);
        }
        setPageLoading(false);
      } catch (e) {
        console.error(e);
      }
    })();
  }, [clientId, itemData.GroupID, dispatch, getAccessTokenSilently]);

  // used for attachment count
  useEffect(() => {
    (async () => {
      try {
        if (attachments.length > 0) {
          const attach = attachments.filter((item) => {
            return (
              item.RecordID === itemData.GroupID &&
              item.RecordType === "StakeholderGroups"
            );
          });
          if (attach && attach.length > 0)
            setAttachCount({ Count: attach.length });
        }
      } catch (e) {
        console.error(e);
      }
    })();
  }, [attachmentList]);

  // get list of contacts for token autocomplete field
  useEffect(() => {
    if (!contactGroupIsLoading) {
      let concat = contacts.filter((contact) => {
        return contactGroups.some((group) => {
          return group.ContactID === contact.ContactID;
        });
      });
      setSelectedContacts(concat);
    }
    return () => {
      // cleanup
    };
  }, [itemData.GroupID, contactGroupIsLoading, contactIsLoading, isEditing]);

  // get lists for issues  autocomplete fields

  useEffect(() => {
    // effect
    if (!groupIssueIsLoading) {
      let newList: string[] = [];
      groupIssues.map((item) => newList.push(item.IssueName));
      setListIssues(newList);
    }
    return () => {
      // cleanup
    };
  }, [itemData.GroupID, groupIssueIsLoading, isEditing]);

  // save when changes made to child records
  useEffect(() => {
    if (didSaveInventory) {
      submitFunc(itemData);
      setDidSaveInventory(false);
    }
    return () => {
      // cleanup
    };
  }, [didSaveInventory]);

  const onSub = (values: any) => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        if (ft_eng_PILSD) {
          values = {
            ...values,
            ProximityIndicatorLSDs: Array.isArray(values.ProximityIndicatorLSDs)
              ? values.ProximityIndicatorLSDs.filter(
                  (pilsd: any) =>
                    pilsd.ProximityIndicator !== "" ||
                    pilsd.LegalSubdivision !== ""
                )
              : [],
          };
        }
        dispatch(
          addStakeholderGroup(
            accessToken,
            values,
            selectedContacts,
            listIssues,
            true,
            snackbarConstants
          )
        );
      } catch (e) {
        console.error(e);
      }
    })();
  };

  let submitFunc = onSub;

  if (itemData.GroupID) {
    //Update case
    submitFunc = (values: any) => {
      (async () => {
        try {
          const accessToken = await getAccessTokenSilently({
            authorizationParams: {
              audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
            },
          });
          if (ft_eng_PILSD) {
            values = {
              ...values,
              ProximityIndicatorLSDs: Array.isArray(
                values.ProximityIndicatorLSDs
              )
                ? values.ProximityIndicatorLSDs.filter(
                    (pilsd: any) =>
                      pilsd.ProximityIndicator !== "" ||
                      pilsd.LegalSubdivision !== ""
                  )
                : [],
            };
          }
          dispatch(
            updStakeholderGroup(
              accessToken,
              values.GroupID,
              values,
              selectedContacts,
              listIssues,
              true,
              snackbarConstants
            )
          );
        } catch (e) {
          console.error(e);
        }
      })();
    };
  } else {
    itemData.GroupName = "";
    itemData.OrganizationTypeID = -1;
    itemData.IndigenousGroupID = -1;
    itemData.LevelOfInfluence = 0;
    itemData.LevelOfInterest = 0;
    itemData.LevelOfFocus = 0;
    itemData.GroupDetails = "";
    itemData.Indigenous = false;
    itemData.Address = "";
    itemData.TownCity = "";
    itemData.ProvinceState = "";
    itemData.CountryName = "";
    itemData.RelationshipOwner = -1;
    itemData.PhoneNumber = "";
    itemData.GroupEmail = "";
    itemData.ProximityIndicatorLSDs = [];
  }

  const handleDelete = () => {
    // function to delete current community investment entry
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        dispatch(
          delStakeholderGroup(accessToken, itemData.GroupID, snackbarConstants)
        );
      } catch (e) {
        console.error(e);
      }
    })();
  };

  const handleTabSelect = (event: any, newValue: any) => {
    setActiveTab(newValue);
  };

  const onCloseDuplicateCheck = () => {
    setShowDuplicateCheck(false);
  };

  const formik = useFormik({
    initialValues: itemData,
    validationSchema: ValidationSchema(itemData, stakeholdergroups),
    onSubmit: submitFunc,
  });

  const commTableData = commitments.map((p) => {
    let newItem = { ...p } as any;
    newItem.RecordedDate
      ? (newItem.RecordedDate = ConvertDateOffset(p.RecordedDate))
      : (newItem.RecordedDate = null);
    return newItem;
  });

  useEffect(() => {
    if (commTableData.length > 0 && !commitmentIsLoading) {
      if (
        !commTableData.every((comm) =>
          comm.hasOwnProperty("PersonResponsibleName")
        )
      ) {
        (async () => {
          try {
            const accessToken = await getAccessTokenSilently({
              authorizationParams: {
                audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
              },
            });
            dispatch(fetchCommitmentsByGroup(accessToken, itemData.GroupID));
          } catch (e) {
            console.error(e);
          }
        })();
      }
    }
  }, [
    commTableData,
    itemData.GroupID,
    commitmentIsLoading,
    dispatch,
    getAccessTokenSilently,
  ]);

  const organizationTypeString = `${
    formik.values?.OrganizationTypeName ??
    `* ${t("strGen:general.missingobject", {
      objectname: t("objPlt:objects.group.fields.organizationtype"),
    })}`
  }`;

  const indigenousGroupName =
    !indigenousGroupIsLoading &&
    !indigenousGroupError &&
    indigenousGroups.length > 0 &&
    formik.values.IndigenousGroupID > 0 &&
    indigenousGroupsById[formik.values.IndigenousGroupID]
      ? ` | ${
          indigenousGroupsById[formik.values.IndigenousGroupID]
            .IndigenousGroupName
        }`
      : "";

  let viewForm = (
    <Root>
      <Box display="flex" justifyContent="center">
        <Grid container className={classes.editForm} spacing={1}>
          <Grid item xs={12}>
            <Paper className={classes.boxSpace}>
              <Grid container spacing={1}>
                <IppFormHeader
                  title={t("objPlt:objects.group.name")}
                  isEditing={isEditing}
                  isAdding={isAdding}
                />
                <Grid item xs={6}>
                  <IppFormDivider
                    title={t(
                      "objPlt:objects.group.headers.organizationdetails"
                    )}
                  />
                </Grid>
                {showEdit && (
                  <Grid item xs={6}>
                    <Box
                      display="flex"
                      justifyContent="flex-end"
                      sx={{ paddingTop: 1 }}
                    >
                      <Tooltip
                        title={t("strGen:pages.merge.tooltip", {
                          fieldname: t("objPlt:objects.group.name", {
                            count: 2,
                          }),
                        })}
                      >
                        <IppMergeButton
                          onClick={() => setShowDuplicateCheck(true)}
                        />
                      </Tooltip>
                    </Box>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <IppDisplayField
                    value={formik.values.GroupName}
                    isEditing={isEditing}
                    setIsEditing={setIsEditing}
                    subText={`${organizationTypeString}${indigenousGroupName}`}
                    showEmphasis={true}
                    showEdit={showEdit}
                  />
                </Grid>

                <Grid item xs={6}>
                  <IppDisplayField
                    label={t("objPlt:objects.group.fields.relationshipowner")}
                    value={
                      usersById && usersById[formik.values.RelationshipOwner]
                        ? usersById[formik.values.RelationshipOwner].FirstName +
                          " " +
                          usersById[formik.values.RelationshipOwner].Surname
                        : ""
                    }
                    isEditing={isEditing}
                    setIsEditing={setIsEditing}
                    showEdit={showEdit}
                  />
                </Grid>
                {ft_eng_Importance && !ft_eng_lvlOfEngagement && (
                  <Grid item xs={6}>
                    <IppDisplayField
                      label={t("objPlt:objects.group.fields.importance")}
                      value={
                        levelOfImportancesById &&
                        levelOfImportancesById[
                          formik.values.LevelOfImportanceID
                        ]
                          ? levelOfImportancesById[
                              formik.values.LevelOfImportanceID
                            ].LevelOfImportanceName
                          : ""
                      }
                      isEditing={isEditing}
                      setIsEditing={setIsEditing}
                      showEdit={showEdit}
                    />
                  </Grid>
                )}
              </Grid>

              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <IppDisplayField
                    label={t("objPlt:objects.group.fields.website")}
                    value={formik.values.WebsiteAddress}
                    isEditing={isEditing}
                    setIsEditing={setIsEditing}
                    isWebLink={true}
                    showEdit={showEdit}
                  />
                </Grid>
                <Grid item xs={6}>
                  <IppDisplayField
                    label={t("objPlt:objects.group.fields.phonenumber")}
                    value={formik.values.PhoneNumber}
                    isEditing={isEditing}
                    setIsEditing={setIsEditing}
                    showEdit={showEdit}
                  />
                </Grid>
                <Grid item xs={12}>
                  <IppDisplayChip
                    label={t("objPlt:objects.group.fields.email")}
                    value={formik.values.GroupEmail}
                    isEditing={isEditing}
                    setIsEditing={setIsEditing}
                    showEdit={showEdit}
                  />
                </Grid>
                <Grid item xs={12}>
                  <IppDisplayField
                    value={formik.values.GroupDetails}
                    label={t("objPlt:objects.group.fields.details")}
                    isEditing={isEditing}
                    setIsEditing={setIsEditing}
                    showEdit={showEdit}
                  />
                </Grid>
                <IppLocationDisplay
                  address={formik.values.Address}
                  townCity={formik.values.TownCity}
                  provinceState={formik.values.ProvinceState}
                  country={formik.values.CountryName}
                  postalCode={formik.values.PostalCode}
                  isEditing={isEditing}
                  setIsEditing={setIsEditing}
                  showEdit={showEdit}
                />

                {ft_eng_PILSD && (
                  <>
                    <IppFormDivider
                      title={t("objPlt:platformwide.headers.proximitylsd")}
                    />
                    <Grid item xs={12}>
                      <IppDisplayChip
                        label={""}
                        value={transformPILSDs(
                          formik.values?.ProximityIndicatorLSDs
                        )}
                        isEditing={isEditing}
                        setIsEditing={setIsEditing}
                        showEdit={showEdit}
                      />
                    </Grid>
                  </>
                )}

                {ft_eng_lvlOfEngagement && (
                  <>
                    <IppFormDivider
                      title={t(
                        "objPlt:objects.group.headers.levelsofengagement"
                      )}
                    />
                    <Grid item xs={6}>
                      <IppRating
                        id="LevelOfInfluence"
                        label={t(
                          "objPlt:objects.group.fields.levelofinfluence"
                        )}
                        value={formik.values.LevelOfInfluence}
                        onChangeFunction={formik.handleChange}
                        isEditing={isEditing}
                        setIsEditing={setIsEditing}
                        showEditButton={showEdit}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <IppRating
                        id="LevelOfInterest"
                        label={t("objPlt:objects.group.fields.levelofinterest")}
                        value={formik.values.LevelOfInterest}
                        onChangeFunction={formik.handleChange}
                        isEditing={isEditing}
                        setIsEditing={setIsEditing}
                        showEditButton={showEdit}
                      />
                    </Grid>
                  </>
                )}

                <Grid item xs={12}>
                  <RecordHistoryDisplay
                    createDate={formik.values.CreateDate}
                    lastUpdated={formik.values.LastUpdated}
                    lastUpdatedBy={formik.values.ModifiedBy}
                  />
                  <IppFormButtons
                    isEditing={isEditing}
                    isAdding={isAdding}
                    setIsEditing={setIsEditing}
                    resetFunction={() => formik.resetForm()}
                    showDelete={showEdit}
                    deleteFunction={() => handleDelete()}
                    fetchFunc={fetchDeleteChecksByGroup}
                    info={t("strGen:prompts.deletechecks.unlinkitems")}
                  />
                </Grid>
                <Grid item xs={12}></Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Box>
      {
        <GroupCheckDuplicates
          originalID={formik.values.GroupID}
          isOpen={showDuplicateCheck}
          closeWindow={onCloseDuplicateCheck}
        />
      }
    </Root>
  );

  let editForm = (
    <div>
      {!formik.isSubmitting && (
        <Prompt
          when={formik.dirty}
          message={t("strGen:prompts.unsavedchanges")}
        />
      )}

      <form onSubmit={formik.handleSubmit}>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <IppFormHeader
            title={t("objPlt:objects.group.name")}
            isEditing={isEditing}
            isAdding={isAdding}
          />
        </Box>
        <Box sx={{ my: 1 }}>
          <IppFormDivider
            title={t("objPlt:objects.group.headers.organizationdetails")}
          />
        </Box>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <IppTextField
              id="GroupName"
              label={t("objPlt:objects.group.fields.name")}
              required={true}
              value={formik.values.GroupName}
              onChangeFunction={formik.handleChange}
              touchedExpression={formik.touched.GroupName}
              errorsExpression={formik.errors.GroupName}
              isEditing={isEditing}
              setIsEditing={setIsEditing}
            />
          </Grid>
          <Grid item xs={6}>
            <IppAutocomplete
              id="RelationshipOwner"
              options={users}
              renderOption={(props: any, option: User) =>
                PersonRenderOption(props, option, [option.EmailAddress], "")
              }
              value={allUsers.find((obj) => {
                return obj.UserAccountID === formik.values.RelationshipOwner;
              })}
              onChangeFunction={(event: ChangeEvent, newValue: any) => {
                if (newValue) {
                  formik.setFieldValue(
                    "RelationshipOwner",
                    newValue.UserAccountID
                  );
                } else {
                  formik.setFieldValue("RelationshipOwner", null);
                }
              }}
              label={t("objPlt:objects.group.fields.relationshipowner")}
              isEditing={isEditing}
              setIsEditing={setIsEditing}
              optionLabelFunction={(option: any) =>
                option.FirstName + " " + option.Surname
              }
              errorFunction={formik.errors.RelationshipOwner}
              touchedFunction={formik.touched.RelationshipOwner}
              textValueFunction={formik.values.RelationshipOwner}
              autoPopulate={false}
            />
          </Grid>
          {ft_eng_Importance && !ft_eng_lvlOfEngagement && (
            <Grid item xs={6}>
              <IppAutocomplete
                id="LevelOfImportanceID"
                options={levelOfImportance}
                value={levelOfImportance.find((obj) => {
                  return (
                    obj.LevelOfImportanceID ===
                    formik.values.LevelOfImportanceID
                  );
                })}
                onChangeFunction={(event: ChangeEvent, newValue: any) => {
                  if (newValue) {
                    formik.setFieldValue(
                      "LevelOfImportanceID",
                      newValue.LevelOfImportanceID
                    );
                  } else {
                    formik.setFieldValue("LevelOfImportanceID", -1);
                  }
                }}
                label={t("objPlt:objects.group.fields.importance")}
                touchedFunction={formik.touched.LevelOfImportanceID}
                errorFunction={formik.errors.LevelOfImportanceID}
                isEditing={isEditing}
                setIsEditing={setIsEditing}
                optionLabelFunction={(option: any) =>
                  option.LevelOfImportanceName
                }
                textValueFunction={
                  !levelOfImportanceIsLoading &&
                  !levelOfImportanceError &&
                  formik.values.LevelOfImportanceID > 0 &&
                  levelOfImportancesById[formik.values.LevelOfImportanceID]
                    ? levelOfImportancesById[formik.values.LevelOfImportanceID]
                        .LevelOfImportanceName
                    : ""
                }
                toolTip={t("strGen:tooltips.group.fields.levelsofimportance")}
              />
            </Grid>
          )}

          <Grid item xs={6}>
            <IppAutocomplete
              id="OrganizationTypeID"
              options={clientOrganizationTypes}
              value={organizationTypes.find((obj) => {
                return (
                  obj.OrganizationTypeID === formik.values.OrganizationTypeID
                );
              })}
              onChangeFunction={(event: ChangeEvent, newValue: any) => {
                if (newValue) {
                  formik.setFieldValue(
                    "OrganizationTypeID",
                    newValue.OrganizationTypeID
                  );
                } else {
                  formik.setFieldValue("OrganizationTypeID", -1);
                }
              }}
              label={t("objPlt:objects.group.fields.organizationtype")}
              required={true}
              touchedFunction={formik.touched.OrganizationTypeID}
              errorFunction={formik.errors.OrganizationTypeID}
              isEditing={isEditing}
              setIsEditing={setIsEditing}
              optionLabelFunction={(option: any) => option.OrganizationTypeName}
              textValueFunction={
                !organizationTypesIsLoading &&
                !organizationTypesError &&
                !clientOrganizationTypesIsLoading &&
                !clientOrganizationTypesError &&
                formik.values.OrganizationTypeID > 0 &&
                organizationTypesById[formik.values.OrganizationTypeID]
                  ? organizationTypesById[formik.values.OrganizationTypeID]
                      .OrganizationTypeName
                  : ""
              }
              toolTip={t("strGen:tooltips.group.fields.organizationtype")}
            />
          </Grid>
          <Grid item xs={6}>
            <IppCheckbox
              id="Indigenous"
              label={t("objPlt:platformwide.indigenous.name") + "?"}
              value={formik.values.Indigenous}
              onChangeFunction={(event: ChangeEvent, newValue: any) => {
                if (newValue) {
                  formik.setFieldValue("Indigenous", true);
                } else {
                  formik.setFieldValue("IndigenousGroupID", -1);
                  formik.setFieldValue("Indigenous", false);
                }
              }}
              isEditing={isEditing}
            />
          </Grid>
          {formik.values.Indigenous && (
            <Grid item xs={6}>
              <IppAutocomplete
                id="IndigenousGroupID"
                options={indigenousGroups}
                value={indigenousGroups.find((obj) => {
                  return (
                    obj.IndigenousGroupID === formik.values.IndigenousGroupID
                  );
                })}
                onChangeFunction={(event: ChangeEvent, newValue: any) => {
                  if (newValue) {
                    formik.setFieldValue(
                      "IndigenousGroupID",
                      newValue.IndigenousGroupID
                    );
                  } else {
                    formik.setFieldValue("IndigenousGroupID", -1);
                  }
                }}
                label={t("objPlt:platformwide.indigenous.indigenousgroup")}
                isEditing={isEditing}
                setIsEditing={setIsEditing}
                optionLabelFunction={(option: any) =>
                  option.IndigenousGroupName
                }
                touchedFunction={formik.touched.IndigenousGroupID}
                errorFunction={formik.errors.IndigenousGroupID}
                textValueFunction={
                  !indigenousGroupIsLoading &&
                  !indigenousGroupError &&
                  indigenousGroups.length > 0 &&
                  formik.values.IndigenousGroupID > 0 &&
                  indigenousGroupsById[formik.values.IndigenousGroupID]
                    ? indigenousGroupsById[formik.values.IndigenousGroupID]
                        .IndigenousGroupName
                    : ""
                }
                required={true}
              />
            </Grid>
          )}
        </Grid>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <IppTextField
              id="WebsiteAddress"
              label={t("objPlt:objects.group.fields.website")}
              value={formik.values.WebsiteAddress}
              onChangeFunction={formik.handleChange}
              touchedExpression={formik.touched.WebsiteAddress}
              errorsExpression={formik.errors.WebsiteAddress}
              isEditing={isEditing}
              setIsEditing={setIsEditing}
              externalLink={true}
            />
          </Grid>
          <Grid item xs={6}>
            <IppTextField
              id="PhoneNumber"
              label={t("objPlt:objects.group.fields.phonenumber")}
              value={formik.values.PhoneNumber}
              onChangeFunction={formik.handleChange}
              touchedExpression={formik.touched.PhoneNumber}
              errorsExpression={formik.errors.PhoneNumber}
              isEditing={isEditing}
              setIsEditing={setIsEditing}
            />
          </Grid>
          <Grid item xs={12}>
            <IppMultiTextField
              id="GroupEmail"
              label={t("objPlt:objects.group.fields.email")}
              value={formik.values.GroupEmail}
              setValue={(newValue: any) =>
                formik.setFieldValue("GroupEmail", newValue.toString())
              }
              required={false}
              options={[]}
              touchedFunction={formik.touched.GroupEmail}
              errorFunction={formik.errors.GroupEmail}
              multiple={true}
              autoPopulate={false}
              freeSolo={true}
              isEditing={isEditing}
              setIsEditing={setIsEditing}
              emailSpaceToken={true}
            />
          </Grid>
          <Grid item xs={12}>
            <IppTextField
              id="GroupDetails"
              label={t("objPlt:objects.group.fields.details")}
              required={false}
              value={formik.values.GroupDetails}
              onChangeFunction={formik.handleChange}
              touchedExpression={formik.touched.GroupDetails}
              errorsExpression={formik.errors.GroupDetails}
              multiLine={true}
              isEditing={isEditing}
              setIsEditing={setIsEditing}
            />
          </Grid>
          {isAdding && (
            <Grid item xs={12}>
              <IppContactAutoComplete
                options={contacts}
                selectedContacts={selectedContacts}
                setSelectedContacts={setSelectedContacts}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            {ft_eng_GroupIssue && isAdding && (
              <IppTokenAutocomplete
                id="IssueList"
                label={t("objPlt:objects.group.fields.relatedissues", {
                  count: listIssues,
                })}
                options={issues.map((option) => option.IssueName)}
                selectedValues={listIssues}
                setSelectedValues={setListIssues}
                isEditing={isEditing}
                setIsEditing={setIsEditing}
              />
            )}
          </Grid>

          <IppFormDivider
            title={t("objPlt:platformwide.headers.locationdetails")}
          />
          <IppLocationAutoComplete
            formik={formik}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
          />
          {ft_eng_PILSD && (
            <>
              <Grid xs={12} item>
                <IppFormDivider
                  title={t("objPlt:platformwide.headers.proximitylsd")}
                />
                <IppDynamicPILSD
                  id="ProximityIndicatorLSDs"
                  options={proximityIndicatorLSDs}
                  value={formik.values.ProximityIndicatorLSDs}
                  onChangeFunction={(item: any) => {
                    formik.setFieldValue("ProximityIndicatorLSDs", item);
                  }}
                  touchedFunction={formik.touched.ProximityIndicatorLSDs}
                  label=""
                  isEditing={isEditing || isAdding}
                  setIsEditing={setIsEditing}
                />
              </Grid>
            </>
          )}
          {ft_eng_lvlOfEngagement && (
            <>
              <IppFormDivider
                title={t("objPlt:objects.group.headers.levelsofengagement")}
              />
              <Grid item xs={6}>
                <IppRating
                  id="LevelOfInfluence"
                  label={t("objPlt:objects.group.fields.levelofinfluence")}
                  value={formik.values.LevelOfInfluence}
                  onChangeFunction={formik.handleChange}
                  isEditing={isEditing}
                  setIsEditing={setIsEditing}
                />
              </Grid>
              <Grid item xs={6}>
                <IppRating
                  id="LevelOfInterest"
                  label={t("objPlt:objects.group.fields.levelofinterest")}
                  value={formik.values.LevelOfInterest}
                  onChangeFunction={formik.handleChange}
                  isEditing={isEditing}
                  setIsEditing={setIsEditing}
                />
              </Grid>
            </>
          )}
          <Grid item xs={12}>
            <IppFormButtons
              isEditing={isEditing}
              isAdding={isAdding}
              setIsEditing={setIsEditing}
              resetFunction={() => formik.resetForm()}
              showDelete={true}
              deleteFunction={() => handleDelete()}
              fetchFunc={fetchDeleteChecksByGroup}
              info={t("strGen:prompts.deletechecks.unlinkitems")}
            />
          </Grid>
        </Grid>
      </form>
    </div>
  );

  const commitmentColumns = [
    {
      field: "SequenceID",
      title: t("objCom:objects.commitment.sequenceid"),
      format: "CMT-{0:0}",
      filter: "text",
      columnWidth: 80,
    },
    { field: "CommitmentTitle", title: t("objCom:objects.commitment.name") },
    {
      field: "PersonResponsibleName",
      title: t("objCom:objects.commitment.fields.responsible"),
    },
    {
      field: "RecordedDate",
      title: t("objPlt:platformwide.fields.date"),
      filter: "date",
      format: "{0:d}",
      columnWidth: 120,
    },
  ];

  const grievanceColumns = [
    {
      field: "SequenceID",
      title: t("objStk:objects.grievance.sequenceid"),
      format: "G-{0:0}",
      filter: "text",
      columnWidth: 60,
    },
    { field: "GrievanceName", title: t("objStk:objects.grievance.columnname") },
    {
      field: "ReportedDate",
      title: t("objStk:objects.grievance.fields.datereported"),
      format: "{0:d}",
      filter: "date",
    },
    {
      field: "ResolutionDate",
      title: t("objStk:objects.grievance.fields.dateresolved"),
      format: "{0:d}",
      filter: "date",
    },
    {
      field: "GrievanceStatusText",
      title: t("objStk:objects.grievance.fields.status"),
    },
  ];

  const interactionColumns = [
    {
      field: "CommunicationID",
      title: t("objStk:objects.interaction.sequenceid"),
      format: "C-{0:0}",
      filter: "string",
      columnWidth: 70,
    },
    {
      field: "InteractionTitle",
      title: t("objStk:objects.interaction.fields.title"),
    },
    {
      field: "InteractionTypeName",
      title: t("objStk:objects.interaction.fields.type"),
      icons: true,
      columnWidth: 60,
    },
    {
      field: "InteractionDate",
      title: t("objStk:objects.interaction.fields.date"),
      filter: "date",
      format: "{0:d}",
      columnWidth: 120,
    },
    {
      field: "ProjectName",
      title: t("objStk:objects.interaction.fields.project"),
    },
  ];

  // to show distimction for primary contact
  /*   const contactData = contactGroups.map((p) => {
    let c = Object.assign({}, p) as any;
      c.PrimaryContact = p.IsPrimary
        ? <Star />
        : " ";
      return c;
  }) */

  const contactColumns = [
    {
      field: "ContactName",
      title: t("objPlt:objects.contact.name", { count: 2 }),
    },
    { field: "IsPrimary", title: " ", icons: true, columnWidth: 50 },
    { field: "ContactTitle", title: t("objPlt:objects.contact.fields.title") },
    { field: "PrimaryPhone", title: t("objPlt:objects.contact.fields.phone") },
    {
      field: "Email",
      title: t("objPlt:objects.contact.fields.email"),
      sendEmail: true,
      commaDelimited: true,
    },
  ];

  const issueColumns = [
    { field: "IssueName", title: t("objStk:objects.issue.name") },
    {
      field: "IssueDescription",
      title: t("objStk:objects.issue.fields.description"),
    },
  ];

  let commInvForm =
    pageLoading ||
    clientIsLoading ||
    organizationTypesIsLoading ||
    clientOrganizationTypesIsLoading ||
    indigenousGroupIsLoading ? (
      <LoadingIndicator />
    ) : isAdding ? (
      <Box display="flex" justifyContent="center">
        <Grid container className={classes.editForm} spacing={1}>
          <Grid item xs={12}>
            <Paper className={classes.boxSpace}>{editForm}</Paper>
          </Grid>
        </Grid>
      </Box>
    ) : (
      <Grid container spacing={1}>
        <Grid item xl={4} xs={customBP ? 12 : 5}>
          {isEditing ? (
            <Dialog open={isEditing} fullWidth maxWidth="lg">
              <DialogContent>{editForm}</DialogContent>
            </Dialog>
          ) : (
            <div>{viewForm}</div>
          )}
        </Grid>
        <Grid item xl={8} xs={customBP ? 12 : 7}>
          <Paper>
            <Box sx={{ width: "100%" }}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={activeTab}
                  onChange={handleTabSelect}
                  variant="scrollable"
                  scrollButtons
                  allowScrollButtonsMobile
                >
                  {showCommunications && (
                    <Tab
                      value={0}
                      label={`${t("objStk:objects.interaction.name", {
                        count: 2,
                      })} (${gi.length})`}
                    />
                  )}
                  {showCommitments && (
                    <Tab
                      value={1}
                      label={`${t("objCom:objects.commitment.name", {
                        count: 2,
                      })} (${commitmentGroups.length})`}
                    />
                  )}
                  {showContacts && (
                    <Tab
                      value={2}
                      label={`${t("objPlt:objects.contact.name", {
                        count: 2,
                      })} (${contactGroups.length})`}
                    />
                  )}
                  {ft_eng_Grievance && showGrievances && (
                    <Tab
                      value={3}
                      label={`${t("objStk:objects.grievance.name", {
                        count: 2,
                      })} (${grievanceGroups.length})`}
                    />
                  )}
                  {showIssues && (
                    <Tab
                      value={4}
                      label={`${t("objStk:objects.issue.name", {
                        count: 2,
                      })} (${groupIssues.length})`}
                    />
                  )}
                  {showAttachments && (
                    <Tab
                      value={5}
                      label={`${t("objPlt:objects.group.attachments")} (${
                        attachCount.Count
                      })`}
                    />
                  )}
                </Tabs>
              </Box>
              {showCommunications && (
                <IppTabPanel value={activeTab} index={0}>
                  <Box>
                    <IppChildInventory
                      title={t("objStk:objects.interaction.name")}
                      parentTitle={t("objPlt:objects.group.name")}
                      linkURL="communications"
                      tableData={gi}
                      idField="GroupInteractionID"
                      nameField="InteractionTitle"
                      parentID={itemData.GroupID}
                      relatedField="InteractionID"
                      columns={interactionColumns}
                      showAdd={true}
                      tableName="GroupInteraction"
                      id="CommunicationList"
                      label={t(
                        "objStk:objects.interaction.communicationhistory"
                      )}
                      options={interactions}
                      selectedValues={groupInteractions}
                      setSelectedValues={null}
                      setDidSaveInventory={setDidSaveInventory}
                      parentValue={itemData.GroupName}
                      showOptions={true}
                      secondLinkCell={1}
                    />
                  </Box>
                </IppTabPanel>
              )}
              {/* .../Commitments/Groups page*/}
              <IppTabPanel value={activeTab} index={1}>
                <Box>
                  <IppChildInventory
                    title={t("objCom:objects.commitment.name")}
                    parentTitle={t("objPlt:objects.group.name")}
                    linkURL="commitments"
                    tableData={commitmentGroups}
                    idField="CommitmentID"
                    nameField="CommitmentTitle"
                    parentID={itemData.GroupID}
                    relatedField="CommitmentID"
                    columns={commitmentColumns}
                    showAdd={true}
                    tableName="Commitment"
                    id="CommitmentList"
                    label={t(
                      "objCom:objects.commitment.fields.commitmenthistory"
                    )}
                    options={commitments}
                    selectedValues={listComms}
                    setSelectedValues={setListComms}
                    setDidSaveInventory={setDidSaveInventory}
                    parentValue={itemData.GroupName}
                    showOptions={true}
                    secondLinkCell={1}
                  />
                </Box>
              </IppTabPanel>
              <IppTabPanel value={activeTab} index={2}>
                <Box>
                  <IppChildInventory
                    title={t("objPlt:objects.contact.name")}
                    parentTitle={t("objPlt:objects.group.name")}
                    linkURL="contacts"
                    tableData={contactGroups}
                    idField="ContactGroupID"
                    nameField="ContactName"
                    parentID={itemData.GroupID}
                    relatedField="ContactID"
                    columns={contactColumns}
                    showEdit={true}
                    tableName="ContactGroup"
                    id="ContactList"
                    label={t("objPlt:objects.group.fields.relatedcontacts", {
                      count: selectedContacts,
                    })}
                    options={contacts}
                    selectedValues={selectedContacts}
                    setSelectedValues={setSelectedContacts}
                    setDidSaveInventory={setDidSaveInventory}
                    parentValue={null}
                    showOptions={true}
                  />
                </Box>
              </IppTabPanel>
              {ft_eng_Grievance && (
                <IppTabPanel value={activeTab} index={3}>
                  <Box>
                    <IppChildInventory
                      title={t("objStk:objects.grievance.name")}
                      parentTitle={t("objPlt:objects.group.name")}
                      linkURL="grievances"
                      tableData={gg}
                      idField="GrievanceGroupID"
                      nameField="GrievanceName"
                      parentID={itemData.GroupID}
                      relatedField="GrievanceID"
                      columns={grievanceColumns}
                      showAdd={true}
                      tableName="GrievanceGroup"
                      id="GrievanceList"
                      label={t("objStk:objects.grievance.grievancehistory")}
                      options={grievances}
                      selectedValues={grievanceGroups}
                      setSelectedValues={null}
                      setDidSaveInventory={setDidSaveInventory}
                      parentValue={itemData.GroupName}
                      showOptions={true}
                      secondLinkCell={1}
                    />
                  </Box>
                </IppTabPanel>
              )}

              {ft_eng_GroupIssue && (
                <IppTabPanel value={activeTab} index={4}>
                  <Box>
                    <IppChildInventory
                      title={t("objStk:objects.issue.name")}
                      parentTitle={t("objPlt:objects.group.name")}
                      linkURL="issues"
                      tableData={groupIssues}
                      idField="GroupIssueID"
                      nameField="IssueName"
                      parentID={itemData.GroupID}
                      relatedField="IssueID"
                      columns={issueColumns}
                      showEdit={true}
                      tableName="GroupIssue"
                      id="IssueList"
                      label={t("objPlt:objects.group.fields.relatedissues", {
                        count: listIssues.length,
                      })}
                      options={issues}
                      selectedValues={listIssues}
                      setSelectedValues={setListIssues}
                      setDidSaveInventory={setDidSaveInventory}
                      parentValue={null}
                      showOptions={true}
                    />
                  </Box>
                </IppTabPanel>
              )}
              <IppTabPanel value={activeTab} index={5}>
                <Box>
                  <IppAttachmentInventory
                    recordType="StakeholderGroups"
                    itemID={itemData.GroupID}
                    companyID={formik.values.CompanyID}
                    projectID={0}
                    moduleID={1}
                    title={t("objPlt:objects.group.attachments")}
                    categoryRecordType="StakeholderGroup"
                  />
                </Box>
              </IppTabPanel>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    );

  return <Root>{commInvForm}</Root>;
};
